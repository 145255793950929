import React from 'react';
import { Link } from 'gatsby';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import LetsChat from 'src/Components/ServiceComponents/LetsChat';
import {
  BlueprintBg,
  Columns,
  LinkWrapper,
  Title,
  Paragraph,
} from 'src/Components/ServiceComponents/Options';

import DiagnosticsHeading from 'src/Components/Diagnostics/DiagHeading';
import DiagnosticsBody from 'src/Components/Diagnostics/DiagnosticsBody';
import DiagnosticsSpacecraft from 'src/Components/Diagnostics/DiagnosticsSpacecraft';
import SEO from 'src/Components/SEO';

function Diagnostics(): JSX.Element {
  return (
    <>
      <SEO
        title="Diagnostics Service | Saber Astronautics"
        description="Not just limited to astronautics, Saber diagnostics is powered by machine learning and artificial intelligence, making your systems faster, smarter and safer."
        url="https://www.saberastro.com/diagnostics"
      />
      <Layout>
        <DiagnosticsHeading />
        <DiagnosticsBody />
        <Container.Dark>
          <DiagnosticsSpacecraft />
        </Container.Dark>
        <BlueprintBg>
          <Columns>
            <LinkWrapper>
              <Link to="/mission-design">
                <Title>
                  Need a design? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph style={{ margin: 0 }}>
                  Take a look at our Mission Design services.
                </Paragraph>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/pre-flight">
                <Title>
                  Already have a plan? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph style={{ margin: 0 }}>
                  Take a look at our Pre-Flight services.
                </Paragraph>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/operations">
                <Title>
                  In Orbit? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph>
                  Check out our flight and diagnostic services.
                </Paragraph>
              </Link>
            </LinkWrapper>
          </Columns>
        </BlueprintBg>
        <LetsChat
          image={
            <StaticImage
              className="background-image"
              src="../images/letschat3.jpg"
              alt="Lets chat background"
            />
          }
        />
      </Layout>
    </>
  );
}

export default Diagnostics;
