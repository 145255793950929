import React from 'react';

import * as Base from 'src/page-styles/base.styled';

function DiagnosticsSpacecraft(): JSX.Element {
  return (
    <>
      <Base.Text>
        <Base.Header>Diagnostics Services</Base.Header>
        <Base.Paragraph>
          Spacecraft diagnostics services provided by Saber profiles your
          spacecraft using its bus and payload telemetry. As part of the
          service, Saber machine-learning models are compact and can run on real
          time systems either on-orbit or in the RSOC.
        </Base.Paragraph>
        <Base.Paragraph>
          Depending on application, Saber’s field-tested capabilities
          demonstrate properties of time-invariance and in some cases can last
          for years without re-training.
        </Base.Paragraph>
        <Base.Gap />
      </Base.Text>
    </>
  );
}

export default DiagnosticsSpacecraft;
