import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Container from 'src/Components/ContentContainer';
import * as Base from 'src/page-styles/base.styled';

const Wrapper = styled.div`
  border-top: 8px solid ${({ theme }) => theme.primary500};
`;

function DiagnosticsBody(): JSX.Element {
  return (
    <Wrapper>
      <Container.Dark>
        <Base.Gap />
        <Base.Text>
          <Base.Header>A Pioneer in Machine Learning for Space</Base.Header>
          <Base.Paragraph>
            Most spacecraft normally use a simple limit check to find satellite
            errors. Simple heuristics are great to find out <b>what</b> happens
            but do not tell you <b>why</b>. With the infinite number of things
            that can go wrong in space, operators need a better way.
          </Base.Paragraph>
          <Base.Paragraph>
            Saber Astronautics, decade of experience in machine learning driven
            satellite diagnostics can greatly reduce the risk of your mission.
            Satellite health, signals health, and space traffic monitoring all
            leverage Machine Learning as part of our Platinum Operations.
            Services
          </Base.Paragraph>
        </Base.Text>
        <Base.Gap />
        <StaticImage
          src="../../images/diagnostics/process.png"
          alt="Process visualisation"
        />
      </Container.Dark>
    </Wrapper>
  );
}

export default DiagnosticsBody;
