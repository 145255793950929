import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import * as Base from 'src/page-styles/base.styled';
import * as Hero from 'src/page-styles/hero.styled';

const CustomHeroText = styled(Hero.Text)`
  box-sizing: border-box;

  padding-right: 0px;
  position: relative;

  @media only screen and (min-width: 1024px) {
    padding-right: 352px;
  }

  @media only screen and (min-width: 1200px) {
    padding-right: 512px;
  }
`;

function DiagnosticsHeading(): JSX.Element {
  return (
    <Hero.Wrapper>
      <Hero.Cover />
      <Hero.Cover />
      <Hero.Cover />
      <StaticImage
        className="hero-image"
        src="../../../images/diagnostics/diagnostics.jpg"
        alt="diagnostics background"
      />
      <CustomHeroText>
        <Hero.MajorHeader style={{ textAlign: 'left' }}>
          Diagnostics
        </Hero.MajorHeader>
        <Hero.Header style={{ textAlign: 'left' }}>
          <Base.Yellow>
            Foundational heritage in machine learning for space with the
            technology and power to bring your mission to the next level.
          </Base.Yellow>
        </Hero.Header>
        <Hero.LogoWrapper>
          <StaticImage
            className="logo"
            src="../../../images/diagnostics/satellite-icon.png"
            alt="hero"
          />
        </Hero.LogoWrapper>
      </CustomHeroText>
    </Hero.Wrapper>
  );
}

export default DiagnosticsHeading;
